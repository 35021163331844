.mb-30 {
    margin-bottom: 30px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-0 {
    margin-bottom: 0px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-xxs-30 {
    @media screen and (max-width: 400px) {
        margin-bottom: 30px !important;
    }
}
.mt-sm-30 {
    @media screen and (max-width: 767px) {
        margin-top: 30px;
    }
}
.mb-md-40 {
    @media screen and (max-width: 991px) {
        margin-bottom: 40px;
    }
}
.mt-xs-30 {
    @media screen and (max-width: 575px) {
        margin-top: 30px;
    }
}
.mt-sm-100 {
    @media screen and (max-width: 767px) {
        margin-top: 100px;
    }
}
.mt-xs-20 {
    @media screen and (max-width: 575px) {
        margin-top: 20px;
    }
}
.mb-sm-20 {
    @media screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
}

