.videogames{
    background: var(--darker);
    position: relative;
    padding: 50px 0 65px 0px;
    &-content {
        h2 {
            font-size: 45px;
            color: var(--yellow);
            font-weight: 400;
            @media screen and (max-width: 767px) {
                font-size: responsive 32px 45px;
                font-range: 320px 767px;
            }
        }
       &--item {
            h3 {
                color: #fff;
                font-size: 30px;
                margin-bottom: 25px;
            }

            img {
                margin-bottom: 0px;

                @media screen and (max-width: 991px) {
                    margin-bottom: 50px;
                }
            }
       }
    }
}