/* Bootstrap Framework */
@import './vendors/bootstrap.css';

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');

/* Custom Variables */
@import './variables.css';

/* Base */
@import './base/reset.css'; 

/* Helpers */
@import './helpers/text.css';
@import './helpers/margin.css';

/* Components */
/* @import './components/box.css'; */

/* Sections */
@import './sections/header.css'; 
@import './sections/footer.css'; 
@import './sections/portada.css'; 
@import './sections/videogames.css'; 

/* Pages */
@import './pages/home.css'; 
@import './pages/page.css'; 

/* Helpers responsive */
@import './helpers/responsive.css'; 