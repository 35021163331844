.header{
    background: var(--darker);
    height: 70px;
    .navbar-dark .navbar-toggler {
        color: rgba(255,255,255,.8);
        border: 0px;
    }
    &-nav {
        padding: 0;
        background: var(--darker);
        padding-top: 4px;
    }
    &-navbar{
        li {
            a {
                text-transform: uppercase;
                background-color: transparent;
                padding: 13px !important;
                border-radius: 5px !important;
                border: 1px solid #fff;
                @media screen and (max-width: 767px) {
                    text-align: center;
                    background-color: transparent;
                }

                i {
                    display: none;
                    right: 30px;
                    position: absolute;
                    top: 25px;
                    border: 1px solid #fff;
                    padding: 10px;
                    color: var(--darker);

                    @media screen and (max-width: 575px) {
                        display: inline-block;
                    }
                
                }

                span {
                    color: #fff;
                    @media screen and (max-width: 575px) {
                        display: none;
                    }
                }
            }
        }
    }
    &-logo {
        max-height: 60px;
        @media screen and (max-width: 575px){
            padding: 10px;
            max-width: 220px;
        }

    }
    &-collapse {
        padding-top: 7px;

        
    }
}