html, body {
    min-height: 100%;
    font-family: var(--textfont);
    overflow-x: hidden;
}


* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.no-padding {
    padding-top: 0px !important; 
    padding-left: 0px !important; 
    padding-right: 0px !important; 
    padding-bottom: 0px !important; 
}

.no-padding-right {
    padding-right: 0px !important; 
}
.no-padding-left {
    padding-left: 0px !important; 
}

.max-30 {
    max-width: 30%;
}