
@media screen and (max-width: 767px) {
    .hide_on_mobile {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .hide_on_desktop {
        display: none;
    }
}

.show_mobile, .show_in_sm {
    display: none;
}
@media screen and (max-width: 575px) {
    .show_mobile {
        display: block;
    }
    .hide_mobile {
        display: none;
    }
}


@media screen and (max-width: 767px) {
    .show_in_sm {
        display: block;
    }
    .hide_in_sm {
        display: none;
    }
}



