.page {
    background: #e6e6e6;
    padding: 25px 0px;

    &-content {
        padding: 35px;
        background: #fff;
        position: relative;
        z-index: 999;
        &-titulo {
            font-weight: 300;
            color: #6c6c6c;
            text-transform: uppercase;
            margin-bottom: 35px;
            margin-top: 0px;
            font-size: 32px;
            font-family: 'Roboto Condensed', sans-serif;
        }
        &-subtitulo {
            font-weight: 700;
            color: #6c6c6c;
            text-transform: uppercase;
            margin-bottom: 10px;
            margin-top:30px;
            font-size: 22px;
            font-family: 'Roboto Condensed', sans-serif;
        }
        &-text {
            font-size: 18px;
            line-height: 22px;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 400;
            margin-bottom: 25px;
            color: #6c6c6c;
        }
    }
}