.home {
    position: relative;
    background: #fff;
    &-content {
        padding: 80px 0px;

        &-item {
            margin-bottom: 40px;

            h2 {
                /* color: var(--dark); */
                font-size: 45px;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
    }
    .custom-padding {
        padding-left: 7px;
        padding-right: 7px;
    } 
}