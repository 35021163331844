.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-sm-left {
    @media screen and (max-width: 767px) {
        text-align: left !important;
    }
}