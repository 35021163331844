.portada {
    min-height: 850px;
    background-image: url('../images/portada.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff !important;
    font-family: 'Source Sans Pro';
    display: flex;
    justify-content: center;
    min-width: 300px;
    align-items: flex-start;

    &.no-image {
        background-image: url('../images/portada-sin-imagen.png');
        min-height: 700px;
        .portada-content {
            padding: 60px 0px 70px 0px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: 35px;
    }
    /*@media screen and (max-width: 767px) {
        height: 720px;
    }
    @media screen and (max-width: 575px) {
        height: auto;
        min-height: 515px;
        
    } */
    /* @media screen and (max-width: 500px) {
        height: auto;
        min-height: 600px;
    } */

    &-logo {
        margin-top: 30px;
        margin-bottom: 50px;
        max-width: 280px;
        @media screen and (max-width: 600px) {
            margin-top: 80px;
        }
    }
    &-contact {
        position: absolute;
        right: 20px;
        top: 10px;
        color: #fff;
        background-color: transparent;
        text-transform: uppercase;
        padding: 10px 20px;
        border: 1px solid #fff;
        border-radius: 4px;
    }
    &-upper {
        margin-bottom: 60px;
    }

    &-medium {
        
    }

    &-nav {
        margin-bottom: 20px;
        li {
            color: #fff;
            font-weight: bold;
            font-size: 30px;
            padding: 0px 25px;

            @media screen and (max-width: 767px) {
                font-size: responsive 26px 30px;
                font-range: 320px 767px;
            }
        }
    }

    &-description {
        color: #fff;
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0px;
        display: block;
    }

    &-divisor {
        border-top: 1px solid var(--yellow);
        margin: 35px auto;
    }

    &-content {
        /* padding-top: 65px;
        @media screen and (max-width: 991px) {
            padding-top: 0px;
        } */
        

        h1 {
            font-weight: bold;
            font-size: 70px;
            margin-bottom: 20px;
            @media screen and (max-width: 991px) {
                font-size: responsive 50px 70px;
                font-range: 320px 991px;
            }
        }

        h2 {
            font-size: 45px;
            color: var(--yellow);
            font-weight: 400;
            @media screen and (max-width: 767px) {
                font-size: responsive 32px 45px;
                font-range: 320px 767px;
            }
        }
        
        h3 {
            font-weight: 300;
            font-size: 24px;
            margin-bottom: 10px;
            @media screen and (max-width: 575px) {
                font-size: responsive 14px 24px;
                font-range: 320px 575px;
                line-height: 1.5rem;
            }
        }

        h4 {
            font-size: 30px;
            margin-bottom: 5px;
            font-weight: bold;
            @media screen and (max-width: 767px) {
                font-size: responsive 26px 30px;
                font-range: 320px 767px;
            }
        }

        span {
            text-transform: uppercase;
            font-size: 17px;
            color: #fff;
            margin-bottom: 25px;
            display: block;
        }
    }

}