.footer {
    background-color: var(--darker);
    color: #fff;
    padding: 50px 0px 50px 0px;
    &-logo {
        margin-bottom: 40px;
        max-width: 100%;
        height: 60px;
    }
    &-description {
        font-size: 17px;
        line-height: 22px;
        max-width: 100%;
        display: block;
        text-align: center;
        margin: 0 auto 40px auto;
    }
    &-nav {
        li {
            
            a{
                color: #fff;
                padding-left: 0;
            }
            &:nth-child(1) {
                a {
                    &:after {
                        content: "|";
                        padding-left: 15px
                    }
                }
            }
            &:nth-child(2) {
                a {
                    &:after {
                        content: "|";
                        padding-left: 15px
                    }
                }
            }
        }
    }
    
}